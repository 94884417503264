<template>
    <div id="app">
        <div class="login">
            <div class="login_bg">
                <div class="mask_layer" style="position: relative;">
                    <img src="../assets/img/bg2.png" style="margin-top: px; height: 800px;">
                    <div class="login_box" style="position: absolute; top: 440px; left: 50%; transform: translateX(-50%);">
                        <img src="../assets/img/logo.jpg" alt="" class="logo"
                            style="position: absolute; top: -60px; left: 50%; transform: translateX(-50%); height: 50px;">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm login_form" >
                            <el-form-item prop="userName">
                                <el-input v-model="ruleForm.userName" prefix-icon="el-icon-user-solid" autocomplete="off"
                                    placeholder="User Name" class="el-input"></el-input>
                            </el-form-item>
                            <el-form-item prop="checkPass">
                                <el-input type="password" v-model="ruleForm.checkPass" prefix-icon="el-icon-lock"
                                    show-password placeholder="Password" class="input_size" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-checkbox label="Remember Account and password" v-model="ruleForm.remember"
                                    class="checkRemember"></el-checkbox>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" round @click="submitForm('ruleForm'); login()" class="input_btn"
                                    size="small" style="width: 300px;">Sign In
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
    data() {
        var checkName = (value, callback) => {
            if (!value) {
                return callback(new Error('Please enter userName'));
            }
        };
        var validatePass = (value, callback) => {
            if (value === '') {
                callback(new Error('Please enter password'));
            }
        };

        return {
            ruleForm: {
                userName: '',
                checkPass: '',
                remember: false,
            },
            rules: {
                userName: [
                    { validator: checkName, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass, trigger: 'blur' }
                ]
            }
        }
    },


    mounted() {
        this.ruleForm.userName = Cookies.get('account')
        this.ruleForm.checkPass = Cookies.get('password')
        if (Cookies.get('remember')) {
            this.ruleForm.remember = true;
        }

    },

    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // this.login();
                } else {
                    // console.log('Submit Failed!!');
                    return false;
                }
            });
        },
        login() {
            const url = '/manage/yeseu/login';
            let params = {}
            let i = 0
            // console.log('Cookies-->',Cookies.get('remember'));
            if (Cookies.get('remember')) {
                i++
                // console.log('记住');
                params = { 'userName': this.ruleForm.userName, 'password': this.ruleForm.checkPass };
            } else {
                // console.log('没记住');
                params = { 'userName': this.ruleForm.userName, 'password': this.$md5(this.ruleForm.checkPass) };
            }
            // console.log('params------------>',params);
            this.$request.post(url, params).then(res => {
                // console.log('Response=>',res);
                if (res.data.result == 0) {
                    this.$store.state.user = res.data.obj;
                    // console.log('store===>',this.$store.state.user);
                    // 第一次登录成功，密码进行加密
                    sessionStorage.setItem('userName', this.ruleForm.userName);
                    if (this.ruleForm.remember && i == 0) {
                        Cookies.set("account", this.ruleForm.userName)
                        Cookies.set("password", this.$md5(this.ruleForm.checkPass))
                        sessionStorage.setItem('userType', res.data.obj.userName)
                        Cookies.set('remember', true)
                        // 第二次不加密，
                    } else if (this.ruleForm.remember) {
                        Cookies.set("account", this.ruleForm.userName)
                        Cookies.set("password", this.ruleForm.checkPass)
                        sessionStorage.setItem('userType', res.data.obj.userType)
                        Cookies.set('remember', true)

                    } else {
                        Cookies.set("account", '')
                        Cookies.set("password", '')
                        sessionStorage.setItem('permissionIds', res.data.obj.permissionIds)
                        Cookies.set('remember', '')
                    }
                    // sessionStorage.setItem("userBean", res.data.obj)
                    sessionStorage.setItem("id", res.data.obj.id)
                    sessionStorage.setItem("roleId", res.data.obj.roleId)
                    sessionStorage.setItem("roleName", res.data.obj.roleName)

                    sessionStorage.setItem('permissionIds', res.data.obj.permissionIds)
                    
                    this.$router.push('/home')
                    console.log('Vuex:state=>',this.$store.state.user);
                    console.log('Vuex:state=>',this.$store.state);

                    console.log('Vuex:type=>',this.$store.state.user.userType);
                    this.$message({
                        type: 'success',
                        message: res.data.msg
                    });
                } else {
                    this.$message({
                        type: 'info',
                        message: res.data.msg
                    });
                }
            }).catch((err) => {
                console.log(err);
                // console.log('Login failed');
            })
        },
    }
    ,
    watch: {
        'ruleForm.userName'(newValue, oldValue) {
            // console.log('useName',newValue,oldValue);
            const that = this
            if (newValue = oldValue) {
                setTimeout(function () {
                    Cookies.set("account", '')
                    Cookies.set("password", '')
                    Cookies.set('remember', '')
                    that.ruleForm.checkPass = '';
                    that.ruleForm.remember = false;
                }, 100)

            }
        }

    }
}
</script>

<style lang="scss" scoped>
#app {

    width: 100%;
    height: 100vh;

    .login {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;

        .login_bg {
            width: 100%;
            height: 100%;
            background: url('@/assets/img/bg.jpg') no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .mask_layer {
                .logo {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

            }

        }


    }
}
</style>