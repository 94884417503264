<template>
    <div id="import">
        <div class="Datalog_table">
            <el-tabs v-model="activeName" @tab-click="handleClick" style="padding: 26px;">
                <el-tab-pane label="User Information" name="first">
                    <div class="form_Information">
                        <div style="margin-bottom: 30px; border-bottom: 1px solid #e9e9ef; padding-bottom: 10px; text-align: center; font-size: 30px;">
                            <span style="text-align: center;">Basic Information </span>
                        </div> 
                        <el-form ref="form" :model="update_user_form" label-width="280px" >
                            <el-form-item label="Nick Name">
                                <el-input v-model="userName" class="input_Information"
                                    :disabled="true"></el-input>
                            </el-form-item>
                            <el-form-item label="ID" v-if="false">
                                <el-input v-model="update_user_form.id" class="input_Information"></el-input>
                            </el-form-item>
                            <el-form-item label="Email">
                                <el-input v-model="update_user_form.email" :disabled="true"
                                    class="input_Information"></el-input>
                            </el-form-item>
                            <el-form-item label="Phone">
                                <el-input v-model="update_user_form.phone" :disabled="true"
                                    class="input_Information"></el-input>
                            </el-form-item>
                            <el-form-item label="User Type">
                                <el-input v-model="update_user_form.userTypeText" :disabled="true"
                                    class="input_Information"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>

                </el-tab-pane>
                <el-tab-pane label="User Security" name="second">
                    <div class="form_Information">
                        <div style="margin-bottom: 30px; border-bottom: 1px solid #e9e9ef; padding-bottom: 10px; text-align: center; font-size: 30px;">
                            <span style="text-align: center;">Change Information</span>
                        </div>
                        <el-form ref="form" :model="update_user_form" label-width="280px" >
                            <el-form-item label="Nick Name" :required=" true ">
                                    <el-input v-model=" userName " :disabled="true" class="input_Information" 
                                        placeholder="Please Enter Nick Name">
                                    </el-input>
                                </el-form-item>
                                <!-- <el-form-item label="roleId" :disabled="true">
                                    <el-input v-model=" roleId "  :disabled="true" class="input_Information" 
                                        placeholder="roleId">
                                    </el-input>
                                </el-form-item> -->
                                <el-form-item label="role Name" :required=" true ">
                                    <el-input v-model=" roleName "  :disabled="true" class="input_Information" 
                                        placeholder="roleName">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="New Password" :required=" true ">
                                    <el-input v-model=" update_user_form.passwordDto "  class="input_Information"  type="password">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="Phone">
                                    <el-input v-model=" update_user_form.phone "  class="input_Information"></el-input>
                                </el-form-item>
                                <el-form-item label="Email">
                                    <el-input  v-model=" update_user_form.email "  class="input_Information"></el-input>
                                </el-form-item>

                                <!-- <el-form-item style="display: flex;justify-content: flex-end;">
                                   
                                    <el-button type="primary" style="margin-right: 1000px;"
                                        @click=" updateUser(); ">Submit</el-button>
                                </el-form-item> -->
                        </el-form>
                    </div>
                    <div style="text-align: center;">
                        <el-button type="primary" 
                                        @click=" updateUser(); ">update</el-button>
                    </div>
                                
                </el-tab-pane>

            </el-tabs>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            Update_User: false,
            activeName: 'first',
            update_user_form: {
                id: '',
                userName: '',
                email: '',
                phone: '',
                userTypeText: '',
                roleName:sessionStorage.getItem('roleName'),
            }
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        /**
           *  通过用户名获取用户
           */
        getByUserName() {
            this.$request({ method: 'post', url: '/manage/yeseu/getUserByUserName', params: { userName: this.userName } }).then((res) => {
                console.log('res:', res.data);
                this.update_user_form = res.data.obj;
            })
        },
        /**
         * update user   更新用户数据
         */
        updateUser() {
            const url = '/manage/yeseu/user/updateUser'
            const params = {
                userId: sessionStorage.getItem('id'),
                userName: this.userName,
                password: this.$md5(this.update_user_form.passwordDto),
                phone: this.update_user_form.phone,
                email: this.update_user_form.email,
                remoteCharge: this.update_user_form.remoteChargeText = "Yes" ? 0 : 1,
                billType: this.update_user_form.billTypeText = "Free" ? 0 : 1,
                roleId:sessionStorage.getItem('roleId'),
            }
            console.log('params:', params);
            this.$request.post(url, params).then((res) => {
                if (res.data.result == 0) {
                    this.$message({
                        type: 'success',
                        message: res.data.msg
                    });
                    this.queryUser();
                } else {
                    this.$message({
                        type: 'info',
                        message: res.data.msg
                    });
                }
            }).catch((err) => {
                console.log('err:', err);
            })
        },

    },
    mounted() {
        this.getByUserName()
    },
    created() {
        this.id = sessionStorage.getItem('id');

        this.userName = sessionStorage.getItem('userName');
        this.roleId = sessionStorage.getItem('roleId');
        this.roleName = sessionStorage.getItem('roleName');
       

        console.log(this.roleName);
    },
    watch: {

    }
}
</script>
<style  scoped lang="scss">
#import {
    .input_function {
        margin-top: 10px;
        height: 65px;
        margin-left: 5px;
        // border: 1px solid #1b5de0; 

    }

    .form_Information {
        padding: 20px;

    }

    .Datalog_table {
        width: 80%;
        margin-top: 60px;
        margin-left: 170px;
        height: 70vh;
        border-radius: 0px;
        box-shadow: 0px 2px 20px rgb(1 41 112 / 20%);
        // border: 1px solid #1b5de0;

        .input_Information {
            width: 660px;
            text-align: center;
        }
    }
}

.paging {
    margin-top: 20px;
}





.bottom {
    margin-top: 13px;
    line-height: 12px;
}

.button {
    padding: 0;
    float: right;
}

.image {
    width: 100%;
    display: block;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}</style>