<template>
  <div id="app">
    <index-component v-if="this.$route.path!=='/'"></index-component>
    <router-view v-else-if="this.$route.path=='/'"></router-view>
  </div>
</template>

<script>
import IndexComponent from './components/IndexComponent.vue'
export default {
  components: { IndexComponent },
 
}
</script>

<style lang="scss">
  body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; }


  
</style>
