import axios from 'axios';
import router from '@/router'
// import {Message } from 'element-ui'

//创建axios实例
const service=axios.create({
    // baseURL:"xxx", 
    // baseURL:"http://18.159.81.168:50015",
    baseURL:"https://www.yes-eu-charger.com:8443",
    timeout:20000,
    withCredentials:true,
    // crossDomain:true,
    headers:{
        'Cache-Control': 'no-cache',
        'strict-origin-when-cross-origin':'*',
        'Content-Type': 'application/json; charset=utf-8',
        "Content-Type": "application/x-www-form-urlencoded",
    }
})

//request   请求拦截器
service.interceptors.request.use(request=>{
    // console.log('Request=>',request);
    return Promise.resolve(request)
},err=>{
    console.log('Request Error:',err);
    return Promise.reject(err)
})

//response  响应拦截器
service.interceptors.response.use(response=>{
    // console.log('Response=>',response);
    if(response.data.result==10000){
        /* Message({
            message:response.data.msg || 'Request Error',
            type:'error'
        }) */
        // 登录失败，跳转到首页，重新登录
        router.push('/')
        return Promise.reject(response)
    }else{
        return Promise.resolve(response)
    }
},err=>{
    return Promise.reject(err)
})
 
export default service;