import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/LoginView.vue'
// import ViewUIPlus from 'view-ui-plus';

Vue.use(VueRouter)

const routes = [
  // login
  {
    path: '/',
    name: 'home', 
    component: HomeView
  },
  // index
  {
    path: '/home',
    name: 'Home',
    component: () => import( '../views/HomeView.vue')
  },
  // Setting station
  {
    path: '/station',
    name: 'Station',
    component: () => import('../views/Setting/StationView.vue'),
  },
  //Station Information
  {
    path:'/stationDetails',
    name:'Station Details',
    component:()=> import('../views/Setting/StationDetails.vue')
  },
  // Setting Charger
  {
    path: '/Charger',
    name: 'Charger',
    component: () => import('../views/Setting/ChargerView.vue')
  },
  //Setting User
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/Setting/UserView.vue')
  },
  // Setting Vehicle
  {
    path:'/vehicle',
    name:'Vehicle',
    component:()=>import('../views/Setting/VehicleView.vue')
  },
  // Setting Profile
  {
    path:'/Profile',
    name:'Profile',
    component:()=>import('../views/Setting/Profile.vue')
  },
  // Monitor connector
  {
    path: '/connector',
    name: 'Connector',
    component: () => import('../views/Monitor/ConnectorView.vue')
  },
 
  // Transaction
  {
    path: '/transaction',
    name: 'Transaction',
    component: () => import('../views/Transaction/GroupView.vue')
  },
  // Alarm
  {
    path: '/alarm',
    name: 'Alarm',
    component: () => import('../views/AlarmView.vue')
  },
  {
    path: '/Code',
    name: 'Code',
    component: () => import('../views/CodeView.vue')
  },
  //Firmware
  {
    path:"/firmware",
    name:'Firmware',
    component:()=> import('../views/FirmwareView.vue')
  },
  // Debug logs
  {
    path: '/logs',
    name: 'Logs',
    component: () => import('../views/Debug/LogsView.vue')
  },
  {
    path:'*',
    name:'NotFound',
    component:()=> import('../views/NotFound.vue')
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import('../views/Setting/Information.vue')
  },
  {
    path: '/load',
    name: 'load',
    component: () => import('../views/Setting/loadbalan.vue')
  },
  {
    path: '/Vehicleinformation',
    name: 'Vehicleinformation',
    component: () => import('../views/Setting/VehicleinformationView.vue')
  },
 
  {
    path: '/permission',
    name: 'permission',
    component: () => import('../views/Setting/PermissionView.vue')
  },
  {
    path: '/loginlog',
    name: 'login log',
    component: () => import('../views/Setting/loginlogs.vue')
  },
  {
    path: '/sentpacket',
    name: 'sentpacket',
    component: () => import('../views/system/SendpacketView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/testView.vue')
  },
  {
    path: '/total',
    name: 'total',
    component: () => import('../views/Monitor/TotalView.vue')
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('../views/Inventory/InventoryView.vue')
  },
  {
    path: '/link',
    name: 'link',
    component: () => import('../views/VerifiedLink/Link.vue')
  },
  {
    path: '/ChargePrice',
    name: 'ChargePrice',
    component: () => import('../views/system/ChargePrice.vue')
  },
  {
    path: '/plan',
    name: 'plan',
    component: () => import('../views/Chargingplan/C_plan.vue')
  },
  
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
/* router.beforeEach((to, from, next) => {
  ViewUIPlus.LoadingBar.start();
  next();
});

router.afterEach(route => {
  ViewUIPlus.LoadingBar.finish();
}); */

export default router
