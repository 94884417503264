import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from "./utils/request";
// import ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
import AFTableColumn from 'af-table-column'
// import MD5
import md5 from 'js-md5'
// import moment
import moment from 'moment'

// import echarts
import * as echarts from 'echarts'
//import Googlemap

import {Loader} from '@googlemaps/js-api-loader'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(AFTableColumn)
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.prototype.$request=request
Vue.prototype.$md5 = md5
Vue.prototype.$loader=new Loader({
  // apiKey:'AIzaSyCLf6Yq1y_P1ZsBZmvRFEgOqKPz8sBjqBM',
  apiKey:'AIzaSyB11c3XZoIf9Ft96dp2l5RQ6X566mVTGdk',
  version: "weekly",
  libraries: ['places'],
  language:'en',
  // url:'http://ditu.google.com/maps/api/js'
})


Vue.use(ElementUI,{ locale });
Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCLf6Yq1y_P1ZsBZmvRFEgOqKPz8sBjqBM',
    libraries: 'places', // 如果需要使用自动完成输入框，需要加载 places 库
  },
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
